import { createContext } from "react";

export type ThemeType = "light" | "dark";

type ThemeContextProps = {
  theme: ThemeType;
  toggleTheme: () => void;
};

export const ThemeContext = createContext({} as ThemeContextProps);
